.nav {
  padding: 5px;
  margin: 10px;
}
.nav a {
  margin: 10px;
  border: black solid 2px;
  background-color: navy;
  color: aliceblue;
  padding: 10px;
  text-decoration: none;
  border-radius: 5px;
}
