.class1 {
  padding: 10px;
  margin: 10px;
  background-color: blue;
}
.class2 {
  padding: 10px;
  margin: 10px;
  background-color: rgb(165, 41, 78);
}
